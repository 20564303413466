'use client'

import Cookies from 'js-cookie'
import { createContext, createElement, useContext } from 'react'

const mergeObjects = (a: any, b?: any) => Object.assign({}, a, b)

const COOKIE = JSON.parse(Cookies.get('MH_GEO') || '{}')

export type GeoData = {
  city: string
  country: string
  countryCode: string
  lat: string
  lon: string
  region: string
  timezone: string
}

const GeoContext = createContext<GeoData>({
  country: COOKIE['country'] || 'United States',
  countryCode: COOKIE['countryCode'] || 'US',
  city: COOKIE['city'] || 'Los Angeles',
  region: COOKIE['region'] || 'CA',
  lat: COOKIE['latitude'] || '34.038',
  lon: COOKIE['longitude'] || '-118.4544',
  timezone: COOKIE['x-vercel-ip-timezone'] || 'America/Indiana/Indianapolis',
})

export const useGeo = () => useContext(GeoContext)

export default function GeoProvider(props) {
  const context = useContext(GeoContext)
  return createElement(
    GeoContext.Provider,
    mergeObjects(props, {
      value: context,
    })
  )
}
