'use client'

import { each, isEmpty } from 'lodash'
import React, { createContext, useContext, useEffect, useState } from 'react'

import { default as en } from './translations/locales/en/translation.json'

const locales = { en }

export const I18nContext = createContext<any>({
  t: (tKey, data = {}) => '',
  language: 'en',
})

export const useI18n = () => useContext(I18nContext)

export const I18nProvider = ({ locale = 'en', children }: any) => {
  const [dict, setDict] = useState({ en: { translation: en } })
  const [language, setLanguage] = useState(locale)

  useEffect(() => {
    if (!dict[language]) {
      if (language !== 'en') loadLanguage('en')
      else loadLanguage(language)
    }
  }, [language])

  const loadLanguage = async (locale) => {
    if (!dict[locale]) {
      setDict((dict) => ({
        ...dict,
        [locale]: {},
      }))
    }
    const translation = locales[locale]
    setDict((dict) => ({
      ...dict,
      [locale]: {
        ...dict[locale],
        translation,
      },
    }))
  }

  const t = (tKey, data = {}) => {
    let [namespace, key] = tKey.split(':')
    if (!key) {
      key = namespace
      namespace = 'translation'
    }

    const template =
      dict?.[language]?.[namespace]?.[key] || dict['en']?.[namespace]?.[key]

    if (isEmpty(data)) {
      return template
    }

    let translated = template
    each(data, (val, key) => {
      translated = translated.replace(
        new RegExp(`{{\\s*${key}\\s*}}`, 'g'),
        val
      )
    })
    return translated
  }

  return (
    <I18nContext.Provider value={{ t, language }}>
      {children}
    </I18nContext.Provider>
  )
}

export default I18nProvider

I18nProvider.displayName = 'I18nProvider'
